import React, { useRef } from 'react';
import Slider from 'react-slick';

// Import des styles de slick-carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// Style Scss
import '../../Styles/AccueilMobile.scss';
// Images
import Bio from '../../Images/accueil/accueil_mobilecrop.webp';
import Contact from '../../Images/accueil/contact_mobilecrop.webp';
import Offres from '../../Images/accueil/Offres_mobilecrop.webp';
import Projets from '../../Images/accueil/projets_mobilecrop.webp';
import BioMobile from '../Mobile/BioMobile';
import PresentationMobile from './ProjetsMobile';
import OffresMobiles from './OffresMobiles';
import ContactMobile from './ContactMobile';

export default function AccueilMobile() {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      console.log(`beforeChange: current=${current}, next=${next}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if ((current === 0 && next === 3) || (current === 3 && next === 0)) {
        sliderRef.current.slickGoTo(current);
        return false;
      }
    },
  };

  return (
    <div className='All-slider'>
      <Slider ref={sliderRef} {...settings}>
        <section id="slideBio" className="slideBio">
          <img src={Bio} alt="Image-Bio" />
          <BioMobile />
        </section>
        <section id="slidePres" className="slidePres">
          <img src={Projets} alt="Image-Projet" />
          <PresentationMobile />
        </section>
        <section id="slideOffre" className="slideOffre">
          <img src={Offres} alt="Image-Offre" />
          <OffresMobiles />
        </section>
        <section id="slideContact" className="slideContact">
          <img src={Contact} alt="Image-Contact" />
          <ContactMobile />
        </section>
      </Slider>
    </div>
  );
}
