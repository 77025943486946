import React from 'react'
import { useNavigate } from 'react-router-dom';
// component
import Nav from '../../component/Nav'
import FooterDesk from "../../component/FooterDesk"

import presentationtab from '../../component/PresentationTabMobile';
// style
import '../../Styles/ProjetsDesk.scss'
// Images
import TraitRose from '../../Images/Projet/Frame.png';
import Buttun from '../../Images/Projet/BouttonVoirSite.svg';

export default function Projetsdesk() {
    const navigate = useNavigate();

    const handleProjectClick = (projectId) => {
      navigate(`/projet-details/${projectId}`);
    };
  return (
    <div className='All-projetdesk'>
        <Nav/>

      <div className='trait'>
        <img src={TraitRose} alt='TraitRose' />
      </div>
      <div className='desc'>
        <p>Vous trouverez ici les différents projets sur lesquels nous avons travaillés, <br></br> de manière indépendante ou ensemble.</p>
      </div>

      <div className='All-cadres'>
  {presentationtab.map((project) => (
    <div className='cadre' key={project.id}>
      <img
        src={project.images[0].src} // Accédez à la propriété src ici
        alt={`Project ${project.id} Thumbnail`}
        onClick={() => handleProjectClick(project.id)}
        style={{ cursor: 'pointer' }}
      />
      <div className='corps-cadre'>
        <h2>{project.titre}</h2>
        <p>{project.texte}</p>
        <a
          href={project.redirect || '#'} // Définit un lien par défaut s'il n'y a pas de redirection
          target={project.redirect ? '_blank' : '_self'} // Ouvrir un nouvel onglet seulement si un redirect existe
          rel='noopener noreferrer'
          style={{
            visibility: project.redirect ? 'visible' : 'hidden', // Rendre invisible si pas de redirection
            pointerEvents: project.redirect ? 'auto' : 'none', // Désactiver l'interaction si pas de redirection
          }}
        >
          <img src={Buttun} alt='redirect-site' />
        </a>
      </div>
    </div>
  ))}
</div>


        <div className='projets-footer'>
         <FooterDesk/>
        </div>
    </div>
  )
}
