import React from 'react';
// Style
import '../Styles/FooterDesk.scss';
// Images
import LogoFooter from '../Images/footer/logopoursite.svg';
import ChevronUp from '../Images/footer/flechedroite.svg'; // Make sure to add this image to your project

export default function FooterDesk() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='all-footer-desk'>
        <div className='LogoFooter'>
        <img src={LogoFooter} alt="Footer Logo" />
      </div>
      <div className='mentions'><a href='/mentions-légales'>Mentions Légales</a></div>
      <div className='chevrons'>
        <img src={ChevronUp} alt="Scroll to top" onClick={scrollToTop} />

      </div>
    </div>
  );
}
