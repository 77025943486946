import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// component
import Nav from '../component/Nav';
import FooterDesk from '../component/FooterDesk';
// style
import './../Styles/Form.scss';
// image
import Logo from '../Images/Form/logopoursite.svg';
import Fleches from '../Images/Form/Fleches.svg';

export default function Form() {
  const navigate = useNavigate();
  const [previousPage, setPreviousPage] = useState('');

  useEffect(() => {
    // Vérifie la page précédente (referrer)
    const referrer = document.referrer;
    if (referrer.includes('/contact')) {
      setPreviousPage('/contact');
    } else if (referrer.includes('/offres')) {
      setPreviousPage('/offres');
    }
  }, []);

  const handleBackClick = () => {
    if (previousPage) {
      navigate(previousPage);
    } else {
      // Si pas de referrer, retour à la page par défaut (ex: accueil)
      navigate('/');
    }
  };

  return (
    <div className='all-form'>
      <Nav/>
      <div className=' Titre-fleche'>
        <img className='logo' src={Logo} alt='logo' />
        <img 
          src={Fleches} 
          alt='Fleches' 
          className='fleches'
          style={{ cursor: 'pointer' }}
          onClick={handleBackClick} // Gérer le clic sur la flèche
        />
      </div>
      <div>
        <iframe 
          className='iframe' 
          src="https://docs.google.com/forms/d/e/1FAIpQLSdy3gGhyZ59p5dma_ZMRTbfWHIYHTSuReht4-_1ha0WSHFlhg/viewform?embedded=true"   
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
        >
          Chargement…
        </iframe>
      </div>
      <div className='projets-footer'>
        <FooterDesk/>
      </div>
    </div>
  );
}
