import React, { useState, useEffect } from 'react';
import BioMobile from './Mobile/BioMobile';
import BioDesk from './Desktop/Biodesk';

export default function Accueil() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? <BioMobile /> : <BioDesk />}
    </>
  );
}
