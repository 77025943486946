import React from 'react'

//Style

import '../../Styles/BioDesk.scss';
//Images
import TraitJaune from '../../Images/Bio/Frame.svg';
import FlechesJaunes from '../../Images/Bio/FlechesJaunes.svg'
import Site from '../../Images/Bio/Site.svg'
import Linkedin from '../../Images/Bio/Linkedin.svg'
import Malt from '../../Images/Bio/logoMalt.svg'
import Behance from '../../Images/Bio/Behance.svg'
import Discord from '../../Images/Bio/Discord.svg'
import Conception from '../../Images/Bio/iconUX.svg'
import Mobile from '../../Images/Bio/iconMobile.svg'
import Seo from '../../Images/Bio/iconSEO.svg'
import Facebook from '../../Images/Bio/logoFacebook.svg'


//Composants
import Nav from '../../component/Nav'
import Footer from '../../component/FooterDesk';

export default function Biodesk() {
  return (
    <div className='All-biodesk'>
        <Nav/>
        {/**partie1 */}
        <div className='Partie1'>
            <div className='cadrep1'>
                <img src={TraitJaune} alt='trait' />
                <p>Que vous ayez besoin
                    de refaire un site existant
                    ou d’en créer un nouveau, nous nous occupons
                    de votre projet
                    depuis la création graphique jusqu’au développement
                    et la maintenance
                    du site terminé.</p>
            </div>
            <div className='cadrep1'>
                <img src={TraitJaune} alt='trait' />
                <p>Nous vous accompagnons
                de A à Z pour la création
                de votre site.
               
                Nous créons une relation de confiance et de transparence afin de gérer au mieux tous les pixels de votre projet !</p>
            </div>
            <div className='cadrep1'>
                <img src={TraitJaune} alt='trait' />
                <p>A nous deux, nous possédons
                   
                    toutes les compétences,
                
                    pour vous accompagner
                    de l'idée de départ à l'héhergement et mise en ligne de votre site.</p><br></br>
            </div>
        </div>
        <div className='Flechejaunes'>
            <img src={FlechesJaunes}    alt='Fleche jaunes' />
        </div>

        {/**partie2 */}
        <div className='partie2'>
            <div className='cadre'>
                  <h2><span>Jérémy Vigier</span></h2>
                  <h3>Developpeur Web diplomé en 2022. </h3>
                  <p>Développeur freelance spécialisé en front-end, je conçois des sites vitrines professionnels et modernes, adaptés aux besoins de chaque client. Grâce à une expertise en développement, je mets en place des solutions performantes et sur mesure pour propulser votre présence en ligne</p>
                <div className='reseaux'> 
                <a href='https://arf-dev.fr/' alt='link-Arf-dev.fr' target='_blanck'><img src={Site} alt='réseaux-site'/></a>
                <a href='https://www.linkedin.com/in/jeremy-vigier/' alt='link-Linkedin' target='_blanck'> <img src={Linkedin} alt='réseaux-linkedin'/></a>
                <a href='https://www.malt.fr/profile/jeremyvigier?' alt='link-malt' target='_blanck'><img src={Malt} alt='réseaux-instagram'/></a>
                <a href='https://discord.gg/J7XmZKXT' alt='link-discord' target='_blanck'> <img src={Discord} alt='réseaux-disord'/></a>
                <a href='https://www.facebook.com/profile.php?id=61566617814378' alt='link-facebook' target='_blanck'> <img src={Facebook} alt='réseaux-facebook'/></a>

                </div>
            </div>
            <div className='cadre'>
                  <h2><span>Emilien Grudé</span></h2>
                  <h3>Designer indépendant
                  depuis 2018.</h3>
                  <p>Mes compétences
                  me permettent de travailler sur plusieurs aspects
                  de la création mobile et web. Je réalise également des logotypes et des créations artistiques pour l'identité visuelle de mes clients..</p>
                  <div className='reseaux'>
                  <a href='https://www.eminencegraphique.fr/' alt='link-eminence-graphique.fr' target='_blanck' ><img src={Site} alt='réseaux-site'/></a>
                  <a href='https://www.linkedin.com/in/emilien-grud%C3%A9-b27695131/' alt='link-linkedin' target='_blanck'><img src={Linkedin} alt='réseaux-linkedin'/></a>
                  <a href='https://www.malt.fr/profile/emiliengrude' alt='link-Malt' target='_blanck'><img src={Malt} alt='réseaux-instagram'/></a>
                  <a href='https://www.behance.net/Scraick' alt='link-Behance' target='_blanck'> <img src={Behance} alt='réseaux-behance'/></a>
                  <a href='https://www.facebook.com/profile.php?id=61566617814378' alt='link-facebook' target='_blanck'> <img src={Facebook} alt='réseaux-facebook'/></a>

                  </div>
                </div>
            </div> 
            <div className='Flechejaunes'>
            <img src={FlechesJaunes}    alt='Fleche jaunes' />
            </div>
        
            {/**partie3 */}
            <div className='partie3'>
           
                <div className='cadre'>
                    <img src={Conception} alt="Conception"  classname='img-cadre' />
                    <div >
                      <p>L'ergonomie d'un site Internet doit le rendre <span>intuitif </span>
                      à comprendre et à utiliser.</p>
                      <p>L'UX Design couvre à la fois : 
                        <br/> <span>/</span> L'ergonomie
                        <br/> <span>/</span> L'étude du comportement
                        <br/> <span>/</span> Le contenu
                        <br/> <span>/</span> La navigation</p>
                    </div>  
                </div>
                <div className='cadre'>
                    <img src={Mobile} alt="Conception" classname='img-cadre' />
                    <div >
                      <p>Nous créons des sites web
                      sur mesure.</p>
                      <p>Cela vous permet  :
                        <br/> <span>/</span> De vous démarquer de la conccurrence
                        <br/> <span>/</span> D’améliorer l’expérience de vos clients
                        <br/> <span>/</span> De valoriser vos produits</p>
                    </div>
                </div>
                <div className='cadre'>
                    <img src={Seo} alt="Conception" className='img-cadre' />
                    <div >
                      <p>Le référencement
                        a pour objectif d'intégrer,
                        de positionner et de maintenir votre site internet
                        en tête des classements
                        des moteurs de recherche afin de générer le plus fort trafic.</p>
                    </div>
                   
                </div>
                    
                
               
            </div>
               
        <Footer/>
    </div>
  )
}
