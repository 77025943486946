import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Nav.scss';

// Images
import Logo from "../Images/Nav/Logo.svg";

const links = [
  { path: '/bio', label: 'Présentation', color: '#FFB400' }, // Jaune
  { path: '/projets', label: 'Réalisations', color: '#E86A92' }, // Rose
  { path: '/offres', label: 'Offres', color: '#1EAABC' }, // Bleu
  { path: '/contact', label: 'Contact', color: '#7FB800' }, // Vert
];

export default function Nav() {
  const location = useLocation(); // Hook pour obtenir l'URL actuelle

  return (
    <div className='All-nav'>
      <div className='Logo'>
        <a href='/' alt='retour-accueil'><img src={Logo} alt='Logo-http' /></a>
      </div>
      <div className='Link-nav'>
        {links.map(({ path, label, color }) => (
          <div key={path}>
            <Link 
              to={path} 
              style={{ color: location.pathname === path ? color : '#FFFFFF' }} // Blanc pour les autres
            >
              {label}
            </Link>
          </div>
        ))}
      </div>
      <div className='Logo2'>
        <img src={Logo} alt='Logo-http' />
      </div>
    </div>
  );
}
