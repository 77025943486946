import React from 'react';
import { Link } from 'react-router-dom';
import '../../Styles/AcceuilDesk.scss';
// Images
import Bio from '../../Images/accueil/accueil.webp';
import Contact from '../../Images/accueil/contact.webp';
import Offres from '../../Images/accueil/offres.webp';
import Projets from '../../Images/accueil/realisations.webp';
import OffresSC from '../../Images/accueil/Offres_sanscouleur.webp';
import ContactSC from '../../Images/accueil/contact_sanscouleur.webp';
import ProjetsSC from '../../Images/accueil/realisations_sanscouleur.webp';
import BioSC from '../../Images/accueil/Accueil_sanscouleur.webp';

export default function AccueilDesktop() {
  return (
    <div className="desktop-container">
        
        <div className="item">
          <Link to="/bio">
            <img src={BioSC} alt="Image-Bio" className='image-sc'/>
            <img src={Bio} alt="Image-Bio" className='image-couleur' />
          </Link>
        </div>
        <div className="item">
          <Link to="/projets">
            <img src={ProjetsSC} alt="Image-Projets" className='image-sc' />
            <img src={Projets} alt="Image-Bio" className='image-couleur' />
          
          </Link>
        </div>
        <div className="item">
          <Link to="/offres"> 
            <img src={OffresSC} alt="Image-Offres" className='image-sc' />
            <img src={Offres} alt="Image-Bio" className='image-couleur' />
            
          </Link>
        </div>
        <div className="item">
          <Link to="/contact">
            <img src={ContactSC} alt="Image-Contact" className='image-sc' />
            <img src={Contact} alt="Image-Bio" className='image-couleur' />
           
          </Link>
        </div>
      
    </div>
  );
}
