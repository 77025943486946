import React from 'react'

//styles
import '../Styles/Error404.scss'
// images
import Logoerreur from'../Images/error404/Logoerreur.svg';
import Logo from '../Images/error404/logopoursite.svg';
import Bouton from '../Images/error404/Boutonretour.svg';


export default function Error404() {
  return (
    <div className='all-error404'>
      <div className='Logo'>
        <img src={Logo} alt="Logo-Http"/>
      </div>
      <div className='corp'>
        <div>
          <img src={Logoerreur} alt="Logo-404"/>
        </div>
        <div>
          <p>Il semblerait que vous soyez perdus...
          Voici le chemin pour revenir en lieu sûr !</p>
        </div>
        <div>
          <a href='/' ><img src={Bouton} alt="boutton"/>
          </a>
        </div>

      </div>
    </div>
  )
}
