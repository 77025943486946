import React from 'react'
//composants
import Nav from '../../component/Nav'
import FooterDesk from "../../component/FooterDesk"
//Styles
import '../../Styles/ContactDesk.scss'
//Images
import Module1 from '../../Images/contact/module1.svg'
import Module2 from '../../Images/contact/module2.svg'
import Module3 from '../../Images/contact/module3.svg'
import Module4 from '../../Images/contact/module4.svg'
import Bouton from '../../Images/contact/Bouttoncontact.svg'

export default function Contactdesk() {
  return (
    <div className='All-contacdesk'>
        <Nav/>
        <div className='contact-module'>
            <img src={Module1} alt='img-Module1'/>
            <img src={Module4} alt='img-Module1'/>
            <img src={Module3} alt='img-Module1'/>
            <img src={Module2} alt='img-Module1'/>
        </div>
        <div className='contact-bouton'>
            <a href='/form-contact' alt='Formulaire-contact' target='_blanck'> <img src={Bouton} alt='img-Module1'/></a>
        </div>
        <div className='contact-footer'>
         <FooterDesk/>
        </div>
    </div>
  )
}
