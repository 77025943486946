import React from 'react'
import { useParams } from 'react-router-dom';
//Component

import presentationtab from '../../component/PresentationTabMobile';
//Style
import '../../Styles/ProjetDetaisDesk.scss'
//Images

import FlecheG from '../../Images/Projet/FlecheG.png';
import Nav from '../../component/Nav';
import Trait from '../../Images/Projet/Frame.png'
import FooterDesk from '../../component/FooterDesk';




export default function ProjetDetailsdesk() {
  const { id } = useParams();
  const project = presentationtab.find((proj) => proj.id.toString() === id);

  if (!project) {
    return <div>Projet non trouvé</div>;
  }

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className='All-detailsdesk'>
      <Nav/>
      <div className='trait-rose'><img src={Trait} alt='trait-rose'/></div>
      <div className=' Titre-fleche'>
      <img 
            src={FlecheG} 
            alt='FlecheG' 
            onClick={handleBackClick} 
            style={{ cursor: 'pointer' }}
          />
        <h1>{project.titre}</h1>
    
      </div>
      <div className='desc'><p>{project.texte}</p></div>
      <div className='titreETimage'>
        {project.images.map((image, index) => (
          <div key={index} className='image-container'>
            <img src={image.src} alt={`Project ${id} Image ${index}`} />
            <p>{image.desc}</p>
          </div>
        ))}
      </div>
      <div className='projets-footer'>
         <FooterDesk/>
        </div>

    </div>
    
    
  );
};


