import React from 'react';
import { useParams } from 'react-router-dom';
import presentationtab from '../../component/PresentationTabMobile';
import '../../Styles/ProjetsDetailsMobile.scss';
import Logo from '../../Images/Projet/logo.png';
import FlecheG from '../../Images/Projet/FlecheG.png';

const ProjetDetails = () => {
  const { id } = useParams();
  const project = presentationtab.find((proj) => proj.id.toString() === id);

  if (!project) {
    return <div>Projet non trouvé</div>;
  }

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className='All-details'>
      <div className='Logo'>
        <img src={Logo} alt='Logo' />
      </div>
      <div className='desc'>
        <h1>{project.titre}</h1>
        <p>{project.texte}</p>
      </div>
      <div className='flecheG'>
          <img 
            src={FlecheG} 
            alt='FlecheG' 
            onClick={handleBackClick} 
            style={{ cursor: 'pointer' }}
          />
        </div>
      <div className='titreETimage'>
        {project.images.map((image, index) => (
          <div key={index} className='image-container'>
            <p>{image.desc}</p>
            <img src={image.src} alt={`Project ${id} Image ${index}`} />
          </div>
        ))}
      </div>
      <div className='footer'>

      
      </div>
    </div>
  );
};

export default ProjetDetails;
