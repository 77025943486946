import React from 'react';
import { Routes, Route } from 'react-router-dom';

// composant mobile
import Accueil from '../Pages/Accueil.js';
import Projets from '../Pages/Projets.js';
import Bio from '../Pages/Bio.js';
import ProjetDetails from '../Pages/ProjetsDetails.js';
import Contact from '../Pages/Contact.js';
import Offres from '../Pages/Offres.js';
import Form from '../Pages/Form.js';

// Composant Desktop 

// error
import Error404 from '../Pages/Error404';
import Mentions from '../Pages/Mentions.js';


export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Accueil />} />
      <Route path="/bio" element={<Bio />} />
      <Route path="/projets" element={<Projets />} />
      <Route path="/projet-details/:id" element={<ProjetDetails />} /> 
      <Route path="/offres" element={<Offres />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/form-contact" element={<Form />} />
      <Route path="/mentions-légales" element={<Mentions />} />
      <Route path="*" element={<Error404 />} /> {/* Catch-all route for 404 errors */}
    </Routes>
  );
}
