import React, { useState, useEffect } from 'react';

import OffresMobile from './Mobile/OffresMobiles.js';
import OffresDesk from './Desktop/Offresdesk';

export default function Projets() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Nettoyage de l'écouteur d'événement lors du démontage du composant
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return (
      <>
        {isMobile ? <OffresMobile /> : <OffresDesk />}
      </>
    )
}
