import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Mentions.scss'; // Assurez-vous de créer ce fichier pour le style
import Nav from '../component/Nav';
import FooterDesk from '../component/FooterDesk';
import Fleches from '../Images/Mentions/Fleches.svg';

export default function Mentions() {
    const navigate = useNavigate();
    const [previousPage, setPreviousPage] = useState('');
  
 
  
    const handleBackClick = () => {
      if (previousPage) {
        navigate(previousPage);
      } else {
        // Si pas de referrer, retour à la page par défaut (ex: accueil)
        navigate('/');
      }
    };
  return (
    <div className="mentions-container">
        <Nav/>
        <img 
          src={Fleches} 
          alt='Fleches' 
          className='fleches'
          style={{ cursor: 'pointer' }}
          onClick={handleBackClick} // Gérer le clic sur la flèche
        />
      
      <h1>Mentions Légales</h1>
      <p><strong>Présentation du site</strong></p>
      <p>Le site <strong>HoverTheTop</strong> est une collaboration entre deux autoentrepreneurs indépendants, <strong>Arf-dev</strong> et <strong>Eminance Graphique</strong>, pour promouvoir la création de sites internet sur mesure.</p>
      
      <h2><strong>Arf-dev</strong></h2>
      <p>Nom : Vigier Jérémy</p>
      <p>Adresse : Les Sablons 41800 Villedieu le chateau</p>
      <p>SIRET : 947 667 010 00013</p>
      <p>Email : arfdev.pro@gmail.com</p>
      <p>Site : https://arf-dev.fr/</p>

      <h2><strong>AUTO-GRAPHISTE</strong></h2>
      <p>Nom : Grudé Emilien</p>
      <p>Adresse : 34 rue de Pied Sec 72100 Le Mans </p>
      <p>SIRET : 835 339 326 00059</p>
      <p>Email : emilien.grude@gmail.com</p>
      <p>Site : https://www.eminencegraphique.fr/</p>

      <h2><strong>Hébergement du site</strong></h2>
      <p>Hébergeur : Hostinger</p>
      <p>Adresse : 61 Lordou Vironos Street, 6023 Larnaca, Chypre</p>

      <h2><strong>Propriété intellectuelle</strong></h2>
      <p>L'ensemble des contenus présents sur le site HoverTheTop est la propriété de <strong>Arf-dev</strong> et <strong>AUTO-GRAPHISTE</strong>.</p>

      <h2><strong>Données personnelles</strong></h2>
      <p>Le site respecte la réglementation relative à la protection des données personnelles.</p>

      <h2><strong>Liens hypertextes</strong></h2>
      <p>HoverTheTop décline toute responsabilité concernant les contenus des liens externes mentionnés.</p>
      <div className='projets-footer'>
         <FooterDesk/>
        </div>
    </div>
  );
}
