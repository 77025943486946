import React from 'react';
import { useNavigate } from 'react-router-dom';
import presentationtab from '../../component/PresentationTabMobile';
import '../../Styles/ProjetsMobile.scss';
import FlecheRose from '../../Images/Projet/Fleches.png';
import TraitRose from '../../Images/Projet/Frame.png';
import Buttun from '../../Images/Projet/BouttonVoirSite.svg';

const PresentationMobile = () => {
  const navigate = useNavigate();

  const handleProjectClick = (projectId) => {
    navigate(`/projet-details/${projectId}`);
  };

  return (
    <div className='all-Presmobile'>
      <div className='fleches'>
        <img src={FlecheRose} alt='scroll' />
      </div>
      <div className='trait'>
        <img src={TraitRose} alt='TraitRose' />
      </div>
      <div>
        <p>Vous trouverez ici les différents projets sur lesquels nous avons travaillés, de manière indépendante ou ensemble.</p>
      </div>
      <div className='trait'>
        <img src={TraitRose} alt='TraitRose' />
      </div>

      {presentationtab.map((project) => (
        <div className='cadre' key={project.id}>
          <img
            src={project.images[0].src} // Accédez à la propriété src ici
            alt={`Project ${project.id} Thumbnail`}
            onClick={() => handleProjectClick(project.id)}
            style={{ cursor: 'pointer' }}
          />
          <div className='corps-cadre'>
            <h2>{project.titre}</h2>
            <p>{project.texte}</p>
            
            {/* Affichage conditionnel du bouton */}
            {project.redirect && (
              <a href={project.redirect} target='_blank' rel='noopener noreferrer'>
                <img src={Buttun} alt='redirect-site' />
              </a>
            )}
          </div>
        </div>
      ))}

    </div>
  );
};

export default PresentationMobile;
