import React from 'react';
import ReactDOM from 'react-dom/client'; // Utiliser ReactDOM de 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import Footer from './component/FooterMobile';



// Créer une racine
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Footer/>
    </BrowserRouter>
  </React.StrictMode>
);
