import React from 'react'


// styles

import '../../Styles/OffreMobile.scss'

//images

import FlecheBleue from '../../Images/offres/Fleches.svg'
import TraitBleue from '../../Images/offres/Trait.svg'
import Module1 from '../../Images/offres/module1.svg'
import Module2 from '../../Images/offres/module2.svg'
import Module3 from '../../Images/offres/module3.svg'
import Module4 from '../../Images/offres/module4.svg'
import Bouton from '../../Images/offres/Bouttoncontact.svg'


export default function OffresMobiles() {
  return (
    <div className='All-offreMobile'>
        
        <div className='fleches'>
        <img src={FlecheBleue} alt='scroll' />
      </div>
     {/*  <div>
        <p>Nous proposons un service simple, composé d’une formule personnalisée que vous construisez selon vos besoins.</p>
      </div>
      <div className='trait'>
        <img src={TraitBleue} alt='TraitBleue' />
      </div>*/}


      <div className='Expl-Modules'>
        <div className='module'>
            <img src={Module1} alt='Module1' />
        </div>
        <div className='module'>
            <img src={Module2} alt='Module2' />
        </div>
        <div className='module'>
            <img src={Module3} alt='Module3' />
        </div>
        <div className='module'>
            <img src={Module4} alt='Module4' />
        </div>
      </div>
      <div className='fleches'>
        <img src={FlecheBleue} alt='scroll' />
      </div>
      <div className='apres-modules'>
        <h2>Détails de la prestation :</h2>
        <div>
            <p>Notre prestation contient également la prise de photos de votre établissement ainsi que l’écriture du texte de présentation de votre entreprise, basé sur votre histoire et vos valeurs.</p>
        </div>
        <div className='trait'>
        <img src={TraitBleue} alt='TraitBleue' />
      </div>
      <div>
            <p>Un acompte de 25% est demandé avant chaque projet.</p>
        </div>
        <div className='trait'>
        <img src={TraitBleue} alt='TraitBleue' />
      </div>
      <div>
            <p>Vous recevez une maquette finale avant la mise en ligne du site, sur laquelle vous pouvez demander des modifications sur le contenu ou les couleurs choisies.</p>
        </div>
        <div className='trait'>
        <img src={TraitBleue} alt='TraitBleue' />
      </div>
      <div>
            <p>Si vous avez des demandes spécifiques pour l’ajout de modules supplémentaires, ou pour votre site, <span>contactez-nous pour un devis personnalisé !</span></p>
        </div>
        <div className='fleches'>
        <img src={FlecheBleue} alt='scroll' />
      </div>
      <div className='contact-bouton'>
            <a href='/form-contact' alt='Formulaire-contact' target='_blanck'> <img src={Bouton} alt='img-Module1'/></a>
        </div>
      </div>
    </div>
  )
}
