import React, { useState, useEffect } from 'react';

// Styles
import '../Styles/Footer.scss';

// Images
import Logo from '../Images/footer/logopoursite.svg';
import ArrowUp from '../Images/footer/flechedroite.svg'; // Flèche pour le scroll vers le haut

export default function Footer() {
  // Fonction pour faire défiler vers le haut
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='all-footer'>
      <img src={Logo} alt="Logo" className='footer-logo'/>

      <div className='mentions'><a href='/mentions-légales'>Mentions Légales</a></div>
      <div>
        <img src={ArrowUp} alt="Back to top" className='footer-arrow' onClick={scrollToTop}/>
      </div>
    </div>
  );
}
